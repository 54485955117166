<template>
  <!-- eslint-disable -->
  <decorator-icon v-bind="attrs">
    <path clip-rule="evenodd"
          d="M8 32.1542L8 39.9999L15.8458 39.9999C16.8573 39.9999 17.8273 39.5981 18.5426 38.8829L38.883 18.5425C40.3724 17.0531 40.3723 14.6383 38.8829 13.1489L34.851 9.11702C33.3616 7.62765 30.9469 7.62766 29.4575 9.11704L9.11705 29.4574C8.40182 30.1726 8 31.1427 8 32.1542ZM15.8458 37.4573L10.5425 37.4573L10.5426 32.1542C10.5426 31.817 10.6765 31.4936 10.9149 31.2552L25.7979 16.3723L31.6277 22.202L16.7447 37.085C16.5063 37.3234 16.183 37.4573 15.8458 37.4573ZM33.4255 20.4042L37.0851 16.7446C37.5816 16.2481 37.5816 15.4432 37.0851 14.9467L33.0532 10.9149C32.5567 10.4184 31.7518 10.4184 31.2553 10.9149L27.5958 14.5744L33.4255 20.4042Z"
          fill="inherit"
          fill-rule="evenodd"/>
  </decorator-icon>
</template>

<script>
import DecoratorIcon from '../decorator/dp-icon-decorator'
import MixinIcon from '../mixin/dp-icon-mixin'

/**
 * @group Components-icon-svg
 * Standard components
 */
export default {
  name: 'dp-edit-icon',
  mixins: [MixinIcon],
  components: {
    DecoratorIcon
  },
  data() {
    return {
      viewBox: '0 0 48 48'
    }
  }
}
</script>
